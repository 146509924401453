import { SEO_COMPONENT_TYPE } from '../../utils/constants';

/**
 * Set Article SEO
 *
 * @export
 * @return {array}
 */
export function getContentMetaTagsConfig(contentType, content, globalSettings, path, customTitle) {
  const seo = content?.seo?.[0] || {};
  const title = (seo.title || content.title || '').trim();
  const description = (seo.description || content.excerpt || content.description || '').trim();
  const image = seo.image || content.featuredImage;
  const headerTitle =
    customTitle ?? (title ? `${title}${contentType !== 'article' ? ' | BugBug.io' : ''}` : null);

  const defaultCanonical = path ? `${globalSettings.pageDomain}${path}` : null;
  const canonical = seo.canonical || defaultCanonical;

  return [
    {
      __component: SEO_COMPONENT_TYPE.BASIC,
      id: content.id,
      title: headerTitle,
      description,
      canonical,
      noIndex: seo.noIndex,
    },
    {
      __component: SEO_COMPONENT_TYPE.FACEBOOK,
      id: content.id,
      ogTitle: headerTitle,
      ogDescription: description,
      ogImage: image,
      ogUrl: canonical,
    },
    {
      __component: SEO_COMPONENT_TYPE.TWITTER,
      twitterTitle: headerTitle,
      twitterDescription: description,
      twitterImage: image,
    },
  ];
}
