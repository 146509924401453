import { getGlobalSettings, getSharedData, sendBackendRequest } from '~/utils/api';
import { getStaticSchema } from '~/utils/jsonSchemas';

import Page from './[slug]';

const Frontpage = (props) => <Page {...props} />;

export async function getStaticProps() {
  const [globalSettings] = await getGlobalSettings();
  const sharedData = await getSharedData();

  const [[content]] = await sendBackendRequest({
    path: '/pages',
    payload: {
      populate: 'deep,6',
      filters: {
        slug: globalSettings.homepage.slug,
      },
    },
  });

  if (!content) {
    return {
      notFound: true,
    };
  }

  const schemaType = content?.seo?.[0]?.schema;
  const staticSchema = getStaticSchema(schemaType);

  return {
    props: { content, sharedData, staticSchema },
  };
}

export default Frontpage;
