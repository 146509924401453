import Head from 'next/head';

import PageTemplateProvider from '~/components/PageTemplateProvider/PageTemplateProvider';
import { getStaticSchema } from '~/utils/jsonSchemas';

import MainPageFrame from '../components/Layouts/MainPageFrame/MainPageFrame';
import Meta from '../components/Meta/Meta';
import { getGlobalSettings, getSharedData, sendBackendRequest } from '../utils/api';

const Page = ({ content, pageData, sharedData, staticSchema }) => (
  <>
    {staticSchema && (
      <Head>
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(staticSchema) }}
        />
      </Head>
    )}
    <MainPageFrame
      customMenu={content.customMenu}
      sharedData={sharedData}
      trackEventPageName={content.mixpanelPageName ?? content.title}
    >
      <Meta contentData={content} />
      <PageTemplateProvider
        pageLayout={content.pageContent ?? content.content}
        pageData={pageData}
      />
    </MainPageFrame>
  </>
);

export async function getPagesUrls() {
  const paths = await getPagesPaths();
  return ['/', ...paths.map(({ params }) => `/${params.slug}`)];
}

async function getPagesPaths() {
  const [globalSettings] = await getGlobalSettings();
  const [listOfPages] = await sendBackendRequest({
    path: '/pages',
    payload: {
      populate: '0',
      pagination: {
        limit: 9999,
      },
      filters: {
        slug: { $notIn: ['blog', 'integrations', 'solutions'] },
      },
    },
  });

  const pagesToOmit = [
    globalSettings.homepage.slug,
    globalSettings.blogPage.slug,
    globalSettings.blogCategoryPage.slug,
    globalSettings.blogAuthorPage.slug,
  ];

  const listOfPagesToPrerender = listOfPages
    .filter(({ slug }) => !pagesToOmit.includes(slug))
    .map((singlePage) => ({ params: { slug: singlePage.slug } }));

  return listOfPagesToPrerender;
}

export async function getStaticPaths() {
  return { paths: await getPagesPaths(), fallback: 'blocking' };
}

export async function getStaticProps(ctx) {
  const { slug } = ctx.params;

  const [[content]] = await sendBackendRequest({
    path: '/pages',
    payload: {
      filters: {
        slug,
      },
      populate: 'deep,6',
    },
  });

  if (!content) {
    return {
      notFound: true,
    };
  }

  const [faqData] = await sendBackendRequest({
    path: '/faqs',
  });

  const sharedData = await getSharedData();
  const schemaType = content?.seo?.[0]?.schema;
  const staticSchema = getStaticSchema(schemaType);

  const props = {
    sharedData,
    content,
    staticSchema,
    pageData: {
      faqData,
    },
  };

  // TODO: remove temporary mapper
  // eslint-disable-next-line no-restricted-syntax
  for (const component of content.pageContent) {
    // eslint-disable-next-line no-underscore-dangle
    const name = component.__component;
    if (name === 'page-components.articles-list') {
      component.articles = component.articles.map(
        ({
          content: articleContent,
          seo,
          authors,
          relatedArticles,
          // eslint-disable-next-line camelcase
          article_category,
          ...article
        }) => ({
          ...article,
          article_category: {
            // eslint-disable-next-line camelcase
            slug: article_category.slug,
            // eslint-disable-next-line camelcase
            name: article_category.name,
          },
        }),
      );
    }
  }
  return {
    props,
  };
}

export default Page;
