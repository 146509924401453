import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { useContext } from 'react';

import { SharedDataContext } from '~/contexts/sharedData';

import { SEO_COMPONENT_TYPE } from '../../utils/constants';

import { getContentMetaTagsConfig } from './Meta.helpers';

const Meta = ({ contentData, canonical, type = 'website', customTitle = null }) => {
  const router = useRouter();
  const { settings: globalSettings = { globalSEO: [] } } = useContext(SharedDataContext);
  const defaultSeo = globalSettings.globalSEO;
  const contentSeo = getContentMetaTagsConfig(
    type,
    contentData,
    globalSettings,
    router.asPath.split('?')[0],
    customTitle,
  );

  const renderBasic = (data) => (
    <React.Fragment key="renderBasic">
      {data.title && <title>{data.title}</title>}
      {data.description && <meta name="description" content={data.description} key="description" />}
      {data.noIndex || process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' ? (
        <meta name="robots" content="noindex,nofollow" key="robots" />
      ) : (
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
          key="robots"
        />
      )}
      {data.canonical && <link rel="canonical" href={data.canonical} key="canonical" />}
    </React.Fragment>
  );
  const renderOpenGraph = (data) => (
    <React.Fragment key="renderOpenGraph">
      {data.ogLocale && <meta property="og:locale" content={data.ogLocale} key="og:locale" />}
      {data.ogTitle && <meta property="og:title" content={data.ogTitle} key="og:title" />}
      {data.ogDescription && (
        <meta property="og:description" content={data.ogDescription} key="og:description" />
      )}
      {data.ogUrl && <meta property="og:url" content={data.ogUrl} key="og:url" />}
      {data.ogSiteName && (
        <meta property="og:site_name" content={data.ogSiteName} key="og:site_name" />
      )}
      {data.ogImage && <meta property="og:image" content={data.ogImage.url} key="og:image" />}
      {data.ogImage && (
        <meta property="og:image:height" content={data.ogImage.height} key="og:image:height" />
      )}
      {data.ogImage && (
        <meta property="og:image:width" content={data.ogImage.width} key="og:image:width" />
      )}
      {data.ogImage && (
        <meta
          property="og:image:type"
          content={data.ogImage.ext.replace('.', '')}
          key="og:image:type"
        />
      )}
      {data.ogImage && (
        <meta property="og:image:alt" content={data.ogImage.alternativeText} key="og:image:alt" />
      )}
    </React.Fragment>
  );

  const renderTwitter = (data) => (
    <React.Fragment key="renderTwitter">
      {data.twitterCard && (
        <meta name="twitter:card" content={data.twitterCard} key="twitter:card" />
      )}
      {data.twitterSite && (
        <meta name="twitter:site" content={`@${data.twitterSite}`} key="twitter:site" />
      )}
      {data.twitterTitle && (
        <meta name="twitter:title" content={data.twitterTitle} key="twitter:title" />
      )}
      {data.twitterDescription && (
        <meta
          name="twitter:description"
          content={data.twitterDescription}
          key="twitter:description"
        />
      )}
      {data.twitterImage && (
        <meta property="twitter:image" content={data.twitterImage.url} key="twitter:image" />
      )}
      {data.twitterImage && (
        <meta
          property="twitter:image:alt"
          content={data.twitterImage.alternativeText}
          key="twitter:image:alt"
        />
      )}
    </React.Fragment>
  );

  const renderSection = R.cond([
    [R.propEq('__component', SEO_COMPONENT_TYPE.BASIC), renderBasic],
    [R.propEq('__component', SEO_COMPONENT_TYPE.OPENGRAPH), renderOpenGraph],
    [R.propEq('__component', SEO_COMPONENT_TYPE.TWITTER), renderTwitter],
    [R.T, R.always(null)],
  ]);

  return (
    <Head>
      {/* Global settings */}
      {defaultSeo.map(renderSection)}
      {/* Code level overrides eg. pages with pagination */}
      {canonical && (
        <>
          <link rel="canonical" href={canonical} key="canonical" />
          <meta property="og:url" content={canonical} key="og:url" />
        </>
      )}
      {/* Content overrides */}
      {contentSeo.map(renderSection)}
      <meta property="og:type" content={type} key="og:type" />
    </Head>
  );
};

Meta.propTypes = {
  globalSettings: PropTypes.shape({
    globalSEO: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  contentData: PropTypes.shape({
    seo: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  canonical: PropTypes.string,
  type: PropTypes.string,
};
Meta.defaultProps = {
  globalSettings: {},
  contentData: {},
  type: 'website',
  canonical: '',
};
export default Meta;
